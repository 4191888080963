import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="d-dashscreen" id="grad1">
        <div className="d-profile-header d-flex align-items-center justify-content-between fixed-top">
          <div className="d-flex align-items-center">
            <img src="assets/Info-Images/LOGO.jpg" alt='Logo' className="d-logo-image" />
          </div>
          <a href='/auth' className="d-getstarted text-decoration-none">Signup / Login</a>
        </div>
      </div>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay={200}>
              <h1>DevOps Ark</h1>
              <h4>Devopsark is a tool for going from Code-to-delivery Reliable Software Releases through Build,
                Test, and Deployment Automation from a simple application to multi-tenant solutions based on any
                container technology. The tool has flexibility to interface with little/no knowledge to the
                various participating tools like Source Code Control Systems, Automated Testing, automated
                security scans for product vulnerabilities,Continuous Integration and Delivery and support
                different application runtime environments along with Simple Database management, backups and
                Monitoring service.</h4>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <a href="#about" className="btn-get-started scrollto text-decoration-none">Get Started</a>
              </div>
            </div>
            <div className=" col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay={200}>
              <img src="assets/Info-Images/main-img.png" className="img-fluid animated" alt='main_img' />
            </div>
          </div>
        </div>
      </section>
      <div id="main">
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>About Us</h2>
            </div>
            <div className=" content">
              <div className="text-center">
                <p>
                  In simple terms, DevOpsark is about removing the barriers between traditionally siloed
                  teams, development and operations. The product and its features are provided by Simplify3x
                  Software Private limited.
                </p>
                <a href="#" className="btn-learn-more">Learn More</a>
              </div>
            </div>
          </div>
        </section>
        <section id="why-us" className="why-us section-bg">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                <div className="content">
                  <h3>Why clients choose us?</h3>
                </div>
                <div className="accordion-list">
                  <ul>
                    <li>
                      <a data-bs-toggle="collapse" className="collapse text-decoration-none fw-bold" data-bs-target="#accordion-list-1"><span>01</span> Main principles of DevOpsark
                        <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                      <div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">
                        <p>
                          Automation, continuous delivery, and quick feedback cycle and aim to make a
                          software development process faster and more efficient.
                        </p>
                      </div>
                    </li>
                    <li>
                      <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed text-decoration-none fw-bold"><span>02</span> What is the use
                        of being an evolutionary
                        stretch of the Agile methodology? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                      <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                        <p>
                          DevOpsark utilizes automation to ensure a smooth flow of the SDLC. By
                          promoting a collaborative culture, it offers the scope for quick and
                          continuous feedback so that any glitches are fixed in time and the releases
                          are done faster.
                        </p>
                      </div>
                    </li>
                    <li>
                      <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed text-decoration-none fw-bold"><span>03</span> What is the best
                        of DevOpsark? <i className="bx bx-chevron-down icon-show" /><i className="bx bx-chevron-up icon-close" /></a>
                      <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                        <p>
                          DevOpsark enables this through automation.DevOps streamlines processes,
                          propagates efficient releases, and ensures quality builds. This means the
                          deployment phases are more relaxed, the teams are better rested, and there
                          is immense scope for bringing an innovative approach for resolving issues.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{ backgroundImage: 'url("assets/Info-Images/why-us-section.png")' }} data-aos="zoom-in" data-aos-delay={150}>
                &nbsp;</div>
            </div>
          </div>
        </section>
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Our Features</h2>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                <div className="icon-box">
                  <div className="icon"><i className="bx bxl-dribbble" /></div>
                  <h4><a href="#">Provision infrastructure</a></h4>
                  <p>Good DevOps brings a lot of savings to the cloud, as well. It can smooth out
                    high-friction state management challenges. Sprucing up how you provision cloud services,
                    for example, speeds up deployments.Here we have DevOpsark for you to provision
                    infrastructure of cloud provides such as GCP,AWS,Azure.</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay={200}>
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-file" /></div>
                  <h4><a href="#">Arkapp</a></h4>
                  <p>The feature "Arkapp" is to deploy application on kubernetes who is new to kubernetes and
                    do not have much knowledge on Kubernete</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay={300}>
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-tachometer" /></div>
                  <h4><a href="#">Kubernetes</a></h4>
                  <p>User will be able to manage kubernetes cluster at advance level who already knows about
                    kubernetes. This feature is provided by Devopsark to setup and initialize the basics
                    requirements needed for a cluster.</p>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay={400}>
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-layer" /></div>
                  <h4><a href="#">AWS EC2 Instance Recovery</a></h4>
                  <p>This festure is to recover your AWS EC2 Instance provided by DevOpsark if you lost a
                    keypair for the existing intsnace. We have the approach and process to recover the same
                    instance with another credentials</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="team" className="team section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Team</h2>
            </div>
            <div className="row">
              <div className="col-lg-6" data-aos="zoom-in" data-aos-delay={100}>
                <div className="member d-flex align-items-start">
                  <div className="pic"><img src="assets/Info-Images/Vijay.jpg" className="img-fluid" alt="" /></div>
                  <div className="member-info">
                    <h4>Vijay Daniel</h4>
                    <span>Founder &amp; CEO</span>
                    <p>Founded simplify3x to simplify the challeges faced in agile enivironments while
                      implementing Automation. </p>
                    <div className="social">
                      <a href="#" className="fa fa-facebook" />
                      <a href="#" className="fa fa-twitter" />
                      <a href="#" className="fa fa-linkedin" />
                      <a href="#" className="fa fa-instagram" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay={200}>
                <div className="member d-flex align-items-start">
                  <div className="pic"><img src="assets/Info-Images/Ravi-Reddy.jpg" className="img-fluid" alt="Ravi Reddy" /></div>
                  <div className="member-info">
                    <h4>Ravi Reddy</h4>
                    <span>Co-Founder &amp; CSO </span>
                    <p>Building a High-Performance Operations, Customer Success Teams and Leading the
                      Company's Innovation Strategy</p>
                    <div className="social">
                      <a href="#" className="fa fa-facebook" />
                      <a href="#" className="fa fa-twitter" />
                      <a href="#" className="fa fa-linkedin" />
                      <a href="#" className="fa fa-instagram" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay={300}>
                <div className="member d-flex align-items-start">
                  <div className="pic"><img src="assets/Info-Images/Visakh.jpg" className="img-fluid" alt="" /></div>
                  <div className="member-info">
                    <h4>Visakh S T</h4>
                    <span>CTO &amp; Project Manager</span>
                    <p>Chief Technology Officer at Simplify3x Software Private Limited. Test Manager,
                      responsible for designing the test strategy</p>
                    <div className="social">
                      <a href="#" className="fa fa-facebook" />
                      <a href="#" className="fa fa-twitter" />
                      <a href="#" className="fa fa-linkedin" />
                      <a href="#" className="fa fa-instagram" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay={400}>
                <div className="member d-flex align-items-start">
                  <div className="pic"><img src="assets/Info-Images/Harshit.jpg" className="img-fluid" alt="" /></div>
                  <div className="member-info">
                    <h4>Harshit Sengar</h4>
                    <span>Project Lead &amp; DevOps Engineer</span>
                    <p>Experienced DevOps Engineer with a passion for automation and a strong background in
                      Kubernetes.</p>
                    <div className="social">
                      <a href="#" className="fa fa-facebook" />
                      <a href="#" className="fa fa-twitter" />
                      <a href="#3" className="fa fa-linkedin" />
                      <a href="" className="fa fa-instagram" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="pricing" className="pricing">
          <div className="container" data-aos="fade-up">
            <div className="section-title p-0">
              <h2 className="p-3 pb-3">Pricing</h2>
            </div>
            <div className="row">
              <div className="col-lg-4" data-aos="fade-up" data-aos-delay={100}>
                <div className="box">
                  <h3>Monthly</h3>
                  <h4><sup>$</sup>19<span>per month</span></h4>
                  <ul>
                    <li><img src="assets/Info-Images/Check.png" alt="image8" className="me-2" style={{ width: 20 }} />
                      Provision infrastructure</li>
                    <li><img src="assets/Info-Images/Check.png" alt="image7" className="me-2" style={{ width: 20 }} /> Arkapp
                    </li>
                    <li><img src="assets/Info-Images/Check.png" alt="image6" className="me-2" style={{ width: 20 }} />
                      Kubernetes</li>
                    <li><img src="assets/Info-Images/Check.png" alt="image5" className="me-2" style={{ width: 20 }} /> AWS EC2
                      Instance Recovery</li>
                  </ul>
                  <a href="#1" className="buy-btn text-decoration-none">Get Started</a>
                </div>
              </div>
              <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={200}>
                <div className="box">
                  <h3>Yearly</h3>
                  <h4><sup>$</sup>119<span>per year</span></h4>
                  <ul>
                    <li><img src="assets/Info-Images/Check.png" alt="image1" className="me-2" style={{ width: 20 }} />
                      Provision infrastructure</li>
                    <li><img src="assets/Info-Images/Check.png" alt="image2" className="me-2" style={{ width: 20 }} /> Arkapp
                    </li>
                    <li><img src="assets/Info-Images/Check.png" alt="image3" className="me-2" style={{ width: 20 }} />
                      Kubernetes</li>
                    <li><img src="assets/Info-Images/Check.png" alt="image4" className="me-2" style={{ width: 20 }} /> AWS EC2
                      Instance Recovery</li>
                  </ul>
                  <a href="#" className="buy-btn text-decoration-none">Get Started</a>
                </div>
              </div>
              <div className=" col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={300}>
                <div className="box">
                  <h3>Lifetime</h3>
                  <h4><sup>$</sup>399<span>one time</span></h4>
                  <ul>
                    <li><img src="assets/Info-Images/Check.png" alt="image" className="me-2" style={{ width: 20 }} />
                      Provision infrastructure</li>
                    <li><img src="assets/Info-Images/Check.png" alt="image" className="me-2" style={{ width: 20 }} />
                      Arkapp</li>
                    <li><img src="assets/Info-Images/Check.png" alt="image" className="me-2" style={{ width: 20 }} />
                      Kubernetes</li>
                    <li><img src="assets/Info-Images/Check.png" alt="image" className="me-2" style={{ width: 20 }} />
                      AWS EC2 Instance Recovery</li>
                  </ul>
                  <a href="#" className="buy-btn text-decoration-none">Get Started</a>
                </div>
              </div>
            </div>
          </div>
          <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Contact</h2>
              </div>
              <div className="row">
                <div className="col-lg-5 d-flex align-items-stretch">
                  <div className="info">
                    <div className="address">
                      <i className="bi bi-geo-alt">
                        <img src="assets/Info-Images/Location.png" alt="image" style={{ width: 15 }} />
                      </i>
                      <h4>Location:</h4>
                      <p>Kalyan Nagar Bangalore</p>
                    </div>
                    <div className="email">
                      <i className="bi bi-envelope">
                        <img src="assets/Info-Images/Mail.png" alt="image11" style={{ width: 15 }} />
                      </i>
                      <h4>Email:</h4>
                      <p>devopsarkinfo@simplify3x.com</p>
                    </div>
                    <div className="phone">
                      <i className="bi bi-phone">
                        <img src="assets/Info-Images/Phone.png" alt="image10" style={{ width: 15 }} />
                      </i>
                      <h4>Call:</h4>
                      <p>0 (800) 123 45 67</p>
                    </div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2432.3271766511166!2d77.64184496065486!3d13.020868245317379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae172af1d6baa5%3A0xf9373021ebec604c!2sSimplify3x%20Software%20Private%20Limited!5e0!3m2!1sen!2sin!4v1691488661180!5m2!1sen!2sin"
                      frameBorder={0}
                      style={{ border: 0, width: '100%', height: 290 }}
                      allowFullScreen
                      title="Google Maps Location"
                    />

                  </div>
                </div>
                <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                  <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="name">Your Name</label>
                        <input type="text" name="name" className="form-control" id="name" required />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="name">Your Email</label>
                        <input type="email" className="form-control" name="email" id="email" required />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Subject</label>
                      <input type="text" className="form-control" name="subject" id="subject" required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Message</label>
                      <textarea className="form-control" name="message" rows={10} required defaultValue={""} />
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message" />
                      <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center"><button type="submit">Send Message</button></div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </section></div>

    </div >
  );
}

export default App;
